<template>
    <base-form models-name="lingue" :tabs="tabs" @onSave="onSave">

        <template #info="{model, isGlobalLoading}">
            <h-row justify="center">

                <h-col class="px-0" cols="6">
                    <base-text-field v-model.trim="model.NOME"
                                     label="Nome"
                                     placeholder="Digita il nome della lingua"
                                     hint="Utilizza il nome originale della lingua"
                                     maxlength="500"
                                     :disabled="!!isGlobalLoading"
                                     :rules="[v => !!v || 'Il nome è obbligatorio!']"
                                     prepend-icon="fa-signature"
                    ></base-text-field>
                </h-col>
                <h-col class="px-0" cols="3">
                    <base-text-field v-model.trim="model.SIGLA"
                                     label="Sigla"
                                     placeholder="XX"
                                     hint="Targa"
                                     maxlength="2"
                                     :disabled="!!isGlobalLoading"
                                     :rules="[v => !!v]"
                    ></base-text-field>
                </h-col>

            </h-row>
        </template>

    </base-form>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseForm from '@/components/aaaProject/form/BaseForm';
    import BaseTextField from '@/components/aaaGenerics/form/BaseTextField';

    export default {
        name: 'FormLingua',
        components: {HRow, HCol, BaseForm, BaseTextField},
        data: () => ({
            tabs: [
                {name: 'info', title: 'INFO DI BASE', icon: 'fa-cube', showIf: true}
            ]
        }),
        methods: {
            onSave({model, save}) {
                save(model);
            }
        }
    }
</script>

<style scoped>

</style>
